// import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate"
// import _ from 'lodash'
import axios from "axios"
// import { ElMessage } from 'element-plus'
import { ElNotification } from 'element-plus'
// import router from '../router'
/* eslint-disable */

// initial state
const state = () => ({
  user: {},
  jwt: '',
  loading: false
})

// getters
const getters = {}

// actions
const actions = {
  getUser ({commit, state}, data) {
    // console.log(data, state.jwt)
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_HANDLER_URL + '/me',
          {
            headers: {
              'Authorization': 'Bearer ' + state.jwt
            }
          }
        ).then((data) => {
          console.log('getUser response', data)
          commit('UPDATE_USER', data)
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  },
  createToken ({commit, state, dispatch}, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_HANDLER_URL + '/createtoken', data,
          {
            headers: {
              'Authorization': 'Bearer ' + state.jwt
            }
          }
        ).then((data) => {
          console.log('createToken response', data)
          ElNotification({
            title: 'Success',
            message: 'API Key created',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          dispatch('getUser')
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          ElNotification({
            title: 'Error',
            message: error.response.data.message,
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  getApikey ({commit, state}, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_HANDLER_URL + '/getapikey', data,
          {
            headers: {
              'Authorization': 'Bearer ' + state.jwt
            }
          }
        ).then((data) => {
          // console.log('getApikey response', data.data.apikey)
          resolve(data.data.apikey)
        }, (error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  },
  updateApikeys ({commit, state, dispatch}, data) {
    console.log(data, state.user.apikeys)
    data.apikeys = state.user.apikeys
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_HANDLER_URL + '/updateapikeys', data,
          {
            headers: {
              'Authorization': 'Bearer ' + state.jwt
            }
          }
        ).then((data2) => {
          // console.log('getApikey response', data.data.apikey)
          ElNotification({
            title: 'Success',
            message: data.action === 'update' ? 'API Key updated' : 'API Key deleted',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          if (data.action === 'delete') {
            dispatch('getUser')
          }
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          ElNotification({
            title: 'Error',
            message: error.response.data.message,
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  stripeCreateClient ({commit, state, dispatch}, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_HANDLER_URL + '/stripe/createcustomer', data,
          {
            headers: {
              'Authorization': 'Bearer ' + state.jwt
            }
          }
        ).then((data) => {
          // console.log('getApikey response', data.data.apikey)
          console.log(data)
          dispatch('getUser')
          resolve(data)
        }, (error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  },
  checkoutSession ({commit, state}, data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .post('http://localhost:7916/create-checkout-session', {customerID: state.user.stripe.customerID, price: data},
          {
            headers: {
              'Authorization': 'Bearer ' + state.jwt
            }
          }
        ).then((data) => {
          // console.log('getApikey response', data.data.apikey)
          console.log(data.data)
          location.replace(data.data.url)
          resolve(data)
        }, (error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  },
  createPortalSession ({commit, state}, data) {
    console.log(data)
    // commit('SET_LOADING', {active: true, text: 'Redirecting to Stripe'}, {root: true})
    return new Promise((resolve, reject) => {
      axios
        .post('http://localhost:7916/create-portal-session', {customerID: state.user.stripe.customerID},
          {
            headers: {
              'Authorization': 'Bearer ' + state.jwt
            }
          }
        ).then((data) => {
          // console.log('getApikey response', data.data.apikey)
          console.log(data.data)
          location.replace(data.data.portalUrl)
          commit('SET_LOADING', {active: false, text: ''}, {root: true})
          resolve(data)
        }, (error) => {
          console.log('error: ', error)
          commit('SET_LOADING', {active: false, text: ''}, {root: true})
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  SET_USER (state, data) {
    state.user = data.data.profile
    state.jwt = data.data.token
  },
  UPDATE_USER (state, data) {
    state.user = data.data.user
  },
  SET_LOADING (state, data) {
    state.loading = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
