<template>
  <div class="grid h100" style="align-items: center; justify-content: center;">
    <span>
      <a style="color: #fff;" href="/">404</a>
    </span>
  </div>
</template>

<script>
// import _ from 'lodash'
export default {
  name: '404',
  props: {

  },
  components: {

  },
  data(){
      return {

      }
  },
  methods:{

  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
