<template>
  <div class="grid account h100">

    <div class="grid actions">
      <div class="nav_title">Account Settings</div>
      <div class="grid subnav">
        <el-button class="btn border__left green" :class="{active: open.apikeys}" @click="open.apikeys = true, open.billing = false, newapikey = {open: false, desc: ''}">API Keys</el-button>
        <el-button class="btn border__left green" :class="{active: open.billing}" @click="open.apikeys = false, open.billing = true, newapikey = {open: false, desc: ''}" v-if="NODE === 'development'">Billing</el-button>
      </div>
      <div class="grid footer">

        <Transition name="fade">
          <div class="grid" v-if="open.billing">
            <img src="/static/img/stripe_logo.png">
            <span style="font-weight: 400; font-size: 14px; margin-top: 12px; color: rgba(255,255,255,.5);">Billing is managed by Stripe, you can<br>change your plan at any time.</span>
          </div>
        </Transition>

        <div class="grid" style="margin-top: 120px;">
          <el-button class="btn red border" @click="logout()">Logout</el-button>
        </div>

      </div>
    </div>

    <Transition name="fade">
    <div class="grid apikey_list" v-if="open.apikeys">
      <div class="grid title" style="grid-auto-flow: column; margin-bottom: 40px; justify-content: space-between;">
        <span>API Keys</span>
<!--         <span>
          <el-button class="btn border green xs" @click="newapikey.open = true, $nextTick(() => {$refs.newapikeyInput.focus()})" v-if="!newapikey.open">add key</el-button>
        </span> -->
      </div>

      <div class="grid" style="grid-auto-flow: column; margin-bottom: 20px; justify-content: space-between;">
        <span></span>
        <span>
          <el-button class="btn border green xs" @click="newapikey.open = true, $nextTick(() => {$refs.newapikeyInput.focus()})" v-if="!newapikey.open">add key</el-button>
        </span>
      </div>

      <div class="grid apikeys">

        <Transition name="fade">
          <div class="grid" v-if="newapikey.open">

            <el-form
                ref="refRuleForm"
                :model="newapikey"
                :rules="rules"
                label-width="120px"
                class="grid newapikey"
                status-icon
                label-position="top"
              >

              <div class="grid" style="grid-template-columns:  1fr auto; grid-column-gap: 30px; background-color: rgba(0,0,0,.2); padding: 6px 24px 6px 0; border-radius: 8px; margin-bottom: 60px; box-shadow: 0 0 0px 1px rgba(0,174,72,1) inset;">

                <el-form-item label="" prop="desc" style="margin-bottom: 0;">
                  <el-input type="text" class="small" v-model="newapikey.desc" placeholder="enter API Key description" ref="newapikeyInput" ></el-input>
                </el-form-item>

                <div class="grid" style="grid-auto-flow: column; grid-column-gap: 12px; align-items: center;">
                  <el-button class="btn simple xs" @click="newapikey.open = false">nope</el-button>
                  <el-button class="btn green border xs" @click="addnewapikey('refRuleForm')">add key</el-button>
                </div>

              </div>

            </el-form>

          </div>
        </Transition>

        <div class="grid apikey" v-for="(apikey, index) in $store.state.user.user.apikeys" :key="index" :class="{red: askfordelete.active && askfordelete.object.uid && askfordelete.object.uid === apikey.uid, disabled: askfordelete.active && askfordelete.object.uid && askfordelete.object.uid !== apikey.uid}">

          <div class="grid data">
            <span @click="getapikey(apikey)" v-if="!askfordelete.active">{{apikey.desc}}</span>

            <span v-if="askfordelete.active">
              <span v-if="askfordelete.object.uid && askfordelete.object.uid === apikey.uid">Delete "{{apikey.desc}}"?</span>
              <span v-if="askfordelete.object.uid && askfordelete.object.uid !== apikey.uid">{{apikey.desc}}</span>
            </span>

            <span v-if="!askfordelete.active">
              <el-switch
                v-model="apikey.active"
                class="ml-2"
                size="small"
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                @change="$store.dispatch('user/updateApikeys', {action: 'update', apikey: apikey})"
              />
            </span>

            <span v-if="askfordelete.active && askfordelete.object.uid && askfordelete.object.uid === apikey.uid" class="grid" style="grid-auto-flow: column; grid-column-gap: 12px;">
              <el-button class="btn xs simple" @click="askfordelete.active = false, askfordelete.object = {}">nope</el-button>
              <el-button class="btn xs dark" @click="$store.dispatch('user/updateApikeys', {action: 'delete', apikey: apikey}), askfordelete.active = false, askfordelete.object = {}">yes, delete it</el-button>
            </span>

          </div>

          <div class="grid delete">
            <span v-if="!askfordelete.active"><unicon name="trash-alt" fill="#878787" @click="askfordelete.active = true, askfordelete.object = apikey" /></span>
          </div>
        </div>
      </div>

<!--       <div style="margin-top: 60px;">
        <div ref="card"></div>
        <button v-on:click="purchase">Purchase</button>
      </div> -->

      <!-- <div class="grid" v-if="open.billing">
        <el-button class="btn violet border" @click="$store.dispatch('user/stripeCreateClient')"  v-if="!$store.state.user.user.stripe.active">create client</el-button>
        <el-button class="btn green" @click="$store.dispatch('user/checkoutSession')" v-if="!$store.state.user.user.stripe.activesubscription">checkout-session</el-button>

        <el-button class="btn green" @click="$store.dispatch('user/createPortalSession')" v-if="$store.state.user.user.stripe.activesubscription">go to portal</el-button>
      </div> -->

    </div>
    </Transition>

    <Transition name="fade">
    <div class="grid billing" v-if="open.billing">

      <div class="grid title">
        Billing
      </div>

      <el-button class="btn violet border" @click="$store.dispatch('user/stripeCreateClient')"  v-if="!$store.state.user.user.stripe.active">create client</el-button>
      <el-button class="btn green" @click="$store.dispatch('user/checkoutSession')" v-if="!$store.state.user.user.stripe.activesubscription">checkout-session</el-button>

      <!-- <el-button class="btn green" @click="$store.dispatch('user/createPortalSession')" v-if="$store.state.user.user.stripe.activesubscription">go to portal</el-button> -->

      <div class="grid plans">
        <div
          class="grid plan"
          v-for="(plan, index) in pricing.plans"
          :key="index"
          :class="{active: $store.state.user.user.stripe.plan.id === plan.stripePriceID}"
          @click="loadingFullscreen(plan)"
        >
        <!-- @click="$store.state.user.user.stripe.activesubscription ? $store.dispatch('user/createPortalSession') : $store.dispatch('user/checkoutSession', plan.stripePriceID)" -->
          <!-- {{plan}} -->
          <!-- {{$store.state.user.user.stripe.plan.id}} -->
          <div class="grid name">
            {{plan.name}}
          </div>
          <div class="grid price">
            {{plan.price}}
          </div>
          <div class="grid specs">
            <span v-for="(spec, index) in plan.specs" :key="index">
              {{spec.name}}
            </span>
          </div>
        </div>
      </div>

    </div>
    </Transition>

      <el-dialog
        v-model="apikeyVisible"
        width="fit-content"
        @close="token = ''"
        custom-class="green maxw50"
        center
      >
        <template #header>
          Your API Token
        </template>
        <div class="grid apitoken">
          {{apitoken}}
          <unicon name="copy" fill="#fff" v-clipboard:copy="apitoken" v-clipboard:success="copySuccess" style="justify-self: end; margin-top: 8px;" />
        </div>
<!--         <template #footer>
          <span class="dialog-footer">
            <el-button class="btn dark" @click="apikeyVisible = false">Confirm</el-button>
          </span>
        </template> -->
      </el-dialog>

  </div>
</template>

<script>
// import _ from 'lodash'
import { ElMessage, ElLoading } from 'element-plus'
import checkauth from '@/Mixins/checkauth'
// let stripe = window.Stripe('pk_test_51LfmhOEaM3RjTwhyY6UtXUrryMesEwpS9peGuQ7SWcyWZQySWmM9iBM8EpQkXsFQUFlq9mvQWWZw0iU2eG6MTBEj00CjiIOoIV'),
//     elements = stripe.elements(),
//     card = undefined
var NODE = process.env.NODE_ENV
export default {
  name: 'Account',
  mixins: [checkauth],
  props: {

  },
  components: {

  },
  data(){
      return {
        apikeyVisible: false,
        token: '',
        NODE: NODE,
        askfordelete: {
          active: false,
          object: {}
        },
        open: {
          apikeys: true,
          billing: false
        },
        newapikey: {
          open: false,
          desc: ''
        },
        pricing: {
          plans: [{
            name: 'Membership',
            price: '3,60',
            specs: [{
              name: 'spec',
            }, {
              name: 'spec',
            }, {
              name: 'spec',
            }, {
              name: 'spec',
            }],
            stripePriceID: 'price_1LfrMIEaM3RjTwhy208a5mYL'
          }, {
            name: 'Membership 2',
            price: '8,90',
            specs: [{
              name: 'spec',
            }, {
              name: 'spec',
            }, {
              name: 'spec',
            }, {
              name: 'spec',
            }],
            stripePriceID: 'price_1LfrMsEaM3RjTwhyLN9deJSq'
          }, {
            name: 'Membership 3',
            price: '18,90',
            specs: [{
              name: 'spec',
            }, {
              name: 'spec',
            }, {
              name: 'spec',
            }, {
              name: 'spec',
            }],
            stripePriceID: 'price_1LfrMsEaM3RjTwhyLN9deJSe'
          }]
        },
        rules: {
            desc: [
                { required: true, message: 'please input a description for your API Key', trigger: 'blur' }
          ]
        }
      }
  },
  methods:{
    loadingFullscreen (plan) {
      ElLoading.service({
        lock: true,
        text: 'Redirecting to your Stripe Portal',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$store.state.user.user.stripe.activesubscription ? this.$store.dispatch('user/createPortalSession') : this.$store.dispatch('user/checkoutSession', plan.stripePriceID)
    },
    addnewapikey (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('valid')
          this.$store.dispatch('user/createToken', this.newapikey)
          this.newapikey = {
            open: false,
            desc: ''
          }
        } else {
          console.log('error login')
        }
      })
    },
    // purchase () {
    //   stripe.createToken(card).then(function(result) {
    //     // Access the token with result.token
    //     console.log(result)
    //   })
    // },
    getapikey (apikey) {
      this.$store.dispatch('user/getApikey', apikey).then((token) => {
        console.log(token)
        this.apikeyVisible = true
        this.apitoken = token
      })
    },
    copySuccess () {
      // console.log(e)
      ElMessage({
        message: 'API token copied to clipboard',
        type: 'success',
      })
    }
  },
  computed: {

  },
  mounted () {
    // card = elements.create('card');
    // card.mount(this.$refs.card);
    if (this.$route.meta.billing) {
      this.open.apikeys = false
      this.open.billing = true
    }
    this.$store.commit('SET_LOADING', {active: false, text: ''})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
