// import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate"
// import _ from 'lodash'
import axios from "axios"
/* eslint-disable */

// initial state
const state = () => ({

})

// getters
const getters = {}

// actions
const actions = {
  sendLink ({commit, rootState}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_HANDLER_URL + '/sendmagiclink', data,
        ).then((data) => {
          console.log(data)
          // commit('user/SET_USER', data, {root: true})
          commit('SET_AUTHENTICATED', true, {root: true})
          // commit('SET_ACCOUNT', data.data.profile.email, {root: true})
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          commit('SET_AUTHENTICATED', false, {root: true})
          reject(error)
        })
    })
  },
  checkLink ({commit, rootState}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_HANDLER_URL + '/checkmagiclink', data,
        ).then((data) => {
          console.log(data)
          commit('user/SET_USER', data, {root: true})
          commit('SET_AUTHENTICATED', true, {root: true})
          // commit('SET_ACCOUNT', data.data.profile.email, {root: true})
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          commit('SET_AUTHENTICATED', false, {root: true})
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
