<template>
  <div class="grid navbar">
    
    <div class="grid logo">
      <img src="/static/img/logo_light_n@2x.png">
      <!-- <div class="grid inner_logo">
        <span>N</span>
      </div> -->
    </div>

    <div class="grid actions">
      <span class="action" :class="{active: open.buckets}">
        <unicon name="tachometer-fast-alt" fill="#333" width="40" height="40" @click="show('buckets'), $router.push('/')" />
      </span>
    </div>

    <div class="grid footer">
      <span class="action" :class="{active: open.account}">
        <unicon name="user" fill="#333" width="32" height="32" @click="$store.dispatch('user/getUser'), show('account')" />
      </span>
    </div>

  </div>
</template>

<script>
import checkauth from '@/Mixins/checkauth'
export default {
  name: 'Navbar',
  mixins: [checkauth],
  props: {
    open: Object
  },
  methods: {
    show (section) {
      // console.log('navbar: ', section)
      this.$emit('open', section)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
