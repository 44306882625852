<template>
  <div class="grid dashboard h100 hidden-xs" v-loading="$store.state.loading" :element-loading-text="$store.state.loadingtext">
    
    <Navbar v-on:open="show" :open="open"></Navbar>

    <Buckets v-if="open.buckets"></Buckets>

    <Account v-if="open.account"></Account>

  </div>
</template>

<script>
import checkauth from '@/Mixins/checkauth'
import checkAppversion from '@/Mixins/checkAppversion'
import Navbar from '@/components/Navbar'
import Buckets from '@/components/Buckets'
import Account from '@/components/Account'
export default {
  name: 'Dashboard',
  mixins: [checkauth, checkAppversion],
  props: {
    msg: String
  },
  data () {
    return {
      open: {
        buckets: true,
        account: false
      }
    }
  },
  components: {
    Navbar,
    Buckets,
    Account
  },
  methods: {
    show (section) {
      // console.log('dash: ', section)
      for (var sect in this.open) {
        this.open[sect] = false
      }
      this.open[section] = true
    }
  },
  mounted () {
    // console.log('mounted: ', this.$route.meta)
    if (this.$route.meta.account) { this.show('account') }
    this.$store.commit('SET_LOADING', {active: false, text: ''})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
