<template>
  <div class="grid buckets h100">

    <div class="grid bucketlist">
      <div class="grid actions">
        <div class="grid search">
          <el-input type="text" v-model="search" placeholder="search" v-if="!addroute" clearable />
        </div>
        <div class="grid buttons">
          <el-button class="btn green shadow" style="font-size: 15px;" @click="addroute = !addroute, editroute = false, settingsopen = true, changeroute = true, bucket = {...newbucket}, $store.dispatch('buckets/getDefaults').then(() => {
            bucket.serverstring = $store.state.buckets.defaults.connprotocol + $store.state.buckets.defaults.server + $store.state.buckets.defaults.zone + $store.state.buckets.defaults.domain
            bucket.connprotocol = $store.state.buckets.defaults.connprotocol
            bucket.server = $store.state.buckets.defaults.server
            bucket.zone = $store.state.buckets.defaults.zone
            bucket.domain = $store.state.buckets.defaults.domain
          }), 
          $store.commit('buckets/SET_BACKUPS', []), $nextTick(() => {$refs.routeinput.focus()})" v-if="!addroute">add endpoint</el-button>
          <el-button class="btn simple" @click="addroute = false, settingsopen = false, changeroute = false" v-if="addroute">close</el-button>
        </div>
      </div>

<!--       <span style="color: #fff;">
            {{newbucket.serverstring}}
            </span> -->

<!--       <div class="grid newbucketitem">
        {{newbucket.route}}
      </div> -->

      <div class="grid bucketitem" v-if="addroute" style="margin-bottom: 60px;">
        <div class="grid avatar">
          <img src="/static/img/avatar_dummy.png">
        </div>
        <div class="grid data">
          <div class="route">{{bucket.server}}{{bucket.zone}}{{bucket.domain}}/{{bucket.route}}</div>
          <div class="quota">0 Records / 0MB</div>
        </div>
        <div class="grid actions">
          <div class="grid" style="grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 12px;">
            <unicon name="file-lock-alt" :fill="bucket.settings.public ? '#00AE48' : '#878787'" />
            <unicon name="archive" :fill="bucket.settings.backup ? '#00AE48' : '#878787'" />
            <unicon name="history" :fill="bucket.settings.history ? '#00AE48' : '#878787'" />
          </div>
        </div>
      </div>

      <el-scrollbar height="100%">

        <div class="grid" style="grid-row-gap: 12px;">

          <div class="grid bucketitem" v-for="(bucketi, index) in buckets" :key="index" @click="
          addroute
          ? (bucket = {...bucketi}, bucket.serverstring = (bucketi.server + bucketi.zone + bucketi.domain))
          : (bucket = bucketi,
          bucket.serverstring = (bucketi.server + bucketi.zone + bucketi.domain),
          $store.dispatch('buckets/getBucketdata', bucket),
          editroute = true,
          help.open = false,
          settingsopen = false,
          handleCurrentChange(1)),
          bucketi.settings.public ? showpublicroute = true : showpublicroute = false"
          :class="{opacity50: addroute, active: (bucketi && bucket && bucketi._id === bucket._id)}">
            <div class="grid avatar">
              <img src="/static/img/avatar_dummy.png">
            </div>
            <div class="grid data">
              <div class="route">{{bucketi.server}}{{bucketi.zone}}{{bucketi.domain}}/{{bucketi.route}}</div>
              <div class="quota">{{bucketi.records}} Records / {{bucketi.size}}MB</div>
            </div>
            <div class="grid actions">
              <div class="grid" style="grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 12px;">
                <!-- <unicon name="file-lock-alt" :fill="bucketi.settings.public ? '#00AE48' : '#878787'" /> -->
                <unicon name="file-share-alt" :fill="bucketi.settings.public ? '#00AE48' : '#878787'" />
                <unicon name="archive" :fill="bucketi.settings.backup ? '#00AE48' : '#878787'" />
                <unicon name="history" :fill="bucketi.settings.history ? '#00AE48' : '#878787'" />
              </div>
            </div>
          </div>

        </div>

      </el-scrollbar>

    </div>

    <div class="grid bucketdetails" v-loading="$store.state.buckets.loading">

      <!-- <span style="color: #fff;">{{$store.state.buckets.backups}}</span> -->
      
      <div v-if="addroute || editroute">

        <el-form
            ref="ruleFormRef"
            :model="bucket"
            :rules="rules"
            label-width="120px"
            class="grid newbucket"
            status-icon
            label-position="top"
          >

          <div class="detailtitle" v-if="addroute">
            <!-- <span v-if="addroute">Add route</span> -->
            <!-- <span v-if="settingsopen && editroute">Edit route</span> -->
          </div>
          <!-- <p style="color: #fff;">{{bucket}}</p> -->
          <div class="grid route">
            <div class="grid url" v-if="!changeroute" :class="{public: bucket.settings.public && showpublicroute && !settingsopen}">
              <span v-if="!showpublicroute || settingsopen">{{bucket.connprotocol}}{{bucket.server}}{{bucket.zone}}{{bucket.domain}}/{{bucket.route}}</span>
              <span v-if="showpublicroute && bucket.settings.public && !settingsopen">{{bucket.connprotocol}}public.{{bucket.server}}{{bucket.zone}}{{bucket.domain}}/{{bucket.masked.uid}}</span>
              <div class="grid" style="grid-auto-flow: column; align-items: center; grid-column-gap: 8px;">
                <!-- <el-button class="btn xs border green" v-if="bucket.settings.public" @click="showpublicroute = !showpublicroute">show <span v-if="!showpublicroute">public</span><span v-if="showpublicroute">private</span> route</el-button> -->
                <!-- <el-button @click="showpublicroute = !showpublicroute"><unicon name="rotate-360" fill="#878787" v-if="bucket.settings.public" /></el-button> -->
                <unicon name="copy" fill="#878787" v-if="!settingsopen && !showpublicroute" v-clipboard:copy="bucket.connprotocol + bucket.server + bucket.zone + bucket.domain + '/' + bucket.route" v-clipboard:success="copySuccess" />
                <unicon name="copy" fill="#878787" v-if="!settingsopen && bucket.settings.public && showpublicroute" v-clipboard:copy="bucket.connprotocol + 'public.' + bucket.server + bucket.zone + bucket.domain + '/' + bucket.route" v-clipboard:success="copySuccess" />
                <!-- <unicon name="edit-alt" fill="#878787" v-if="settingsopen" @click="changeroute = true" /> -->
              </div>
            </div>
            <!-- {{$store.state.buckets.defaults.otherservers}} -->
<!--             <span style="color: #fff;">
            {{$store.state.buckets.defaults.connprotocol}}{{$store.state.buckets.defaults.server}}{{$store.state.buckets.defaults.zone}}{{$store.state.buckets.defaults.domain}}
            </span>
            <span style="color: #fff;">
            {{newbucket.serverstring}}
            </span> -->
            <el-form-item label="" v-if="changeroute">
              <el-input type="text" v-model="bucket.route" placeholder="enter your endpoint, ex: newslettersubscribers" ref="routeinput">
                <template #prepend>
                  <!-- {{bucket.server}}{{bucket.zone}}{{bucket.domain}}/ -->
                  <el-select v-model="bucket.serverstring" placeholder="Select"  style="width: fit-content;" popper-class="dark grey">
                    <el-option v-for="(server, index) in $store.state.buckets.defaults.otherservers" :key="index" :label="server.connprotocol + server.server + server.zone + server.domain" :value="server.connprotocol + server.server + server.zone + server.domain" @click="changeServer(server.connprotocol, server.server, server.zone, server.domain)" />
                  </el-select>
                </template>
<!--                 <template #suffix>
                  <unicon name="copy" fill="#878787" v-clipboard:copy="bucket.server + bucket.zone + '.nitros.cloud/' + bucket.route" v-clipboard:success="copySuccess" />
                </template> -->
              </el-input>
            </el-form-item>

            <div class="grid" style="grid-auto-flow: column;">
              <!-- <el-button class="btn tertiary" :class="{disabled: addroute}" @click="saveFile"><unicon name="file-download-alt" fill="#333" /></el-button> -->
              <!-- <el-button class="btn tertiary s40x40" :class="{disabled: addroute}" @click="showpublicroute = !showpublicroute" v-if="bucket.settings.public"><unicon name="rotate-360" fill="#333" /></el-button> -->
              <!-- <el-button class="btn tertiary s40x40" :class="{disabled: addroute}" @click="reloadBucket(bucket)"><unicon name="sliders-v-alt" fill="#ddd" /></el-button> -->
              <el-button class="btn tertiary s40x40" :class="{disabled: addroute}" @click="settingsopen = !settingsopen, help.open = false, changeroute = false, $store.dispatch('buckets/getBackups', bucket)"><unicon name="sliders-v-alt" fill="#333" /></el-button>
              <!-- <el-button class="btn tertiary s40x40" :class="{disabled: addroute}" @click="rapidocsdrawer = true, settingsopen = false, changeroute = false"><unicon name="question-circle" fill="#333" /></el-button> -->
              <el-button class="btn tertiary s40x40" :class="{disabled: addroute}" @click="openDocs"><unicon name="question-circle" fill="#333" /></el-button>
              <!-- <el-button class="btn tertiary" :class="{disabled: addroute}" @click="help.open = false, settingsopen = false, $store.dispatch('buckets/maskBucket', bucket)" v-if="bucket.settings.public"><unicon name="ninja" fill="#333" /></el-button> -->
              <!-- <el-button class="btn tertiary" :class="{disabled: addroute}" @click="$store.dispatch('user/createToken')"><unicon name="info-circle" fill="#333" /></el-button> -->
            </div>

            <Transition name="fade">
              <div class="grid" style="margin-top: 12px; justify-content: space-between; padding-left: 12px; padding-right: 12px; grid-auto-flow: column;">
                <div class="grid" style="justify-content: start; grid-auto-flow: column; grid-column-gap: 16px;" v-if
                ="!settingsopen && !help.open && !changeroute">
                  <el-button class="btn xs border violet" v-if="bucketdata.length > 0" @click="migrationDialog = true">migrate or upload data</el-button>
                  <el-button class="btn xs border violet" v-if="bucketdata.length > 0" @click="saveFile">download data as json</el-button>
                </div>
                <div>
                  <el-button class="btn xs border green round" v-if="bucket.settings.public && !settingsopen" @click="showpublicroute = !showpublicroute">show <span v-if="!showpublicroute">public</span><span v-if="showpublicroute">private</span> endpoint</el-button>
                </div>
              </div>
            </Transition>

          </div>

          

          <Transition name="fade">
          <div class="grid settings_section" v-if="settingsopen">

            <!-- <el-button class="btn green" @click="migrationDialog = true" v-if="!addroute">Migration</el-button> -->

<!--             <el-radio-group v-model="bucket.serverstring" v-if="addroute" class="separated">
              <el-radio-button v-for="(server, index) in $store.state.buckets.defaults.otherservers" :key="index" :label="server.server + server.zone + server.domain" @change="changeServer(server.connprotocol, server.server, server.zone, server.domain)" />
            </el-radio-group> -->

            <div class="grid options_block">
              <div class="title">Safety</div>
              <div class="grid options">
                <el-checkbox-button class="btn" v-model="bucket.settings.public">Public endpoint</el-checkbox-button>
                <el-checkbox-button class="btn" v-model="bucket.settings.backup">Backup</el-checkbox-button>
                <el-checkbox-button class="btn" v-model="bucket.settings.history" disabled>History</el-checkbox-button>
              </div>

              <Transition name="fade">
                <div class="grid options sub_nav">
                  <span></span>
                  <unicon name="ellipsis-h" v-if="bucket.settings.backup && $store.state.buckets.backups.length > 0" @click="$store.dispatch('buckets/getBackups', bucket), backupsdrawer = true" />
                  <!-- <el-button class="btn xs border green" v-if="bucket.settings.backup && $store.state.buckets.backups.length > 0" @click="$store.dispatch('buckets/getBackups', bucket), backupsdrawer = true">Show Backups</el-button> -->
                  <span></span>
                </div>
              </Transition>

              <Transition name="fade">
                <div class="grid sub_options" v-if="bucket.settings.public">
                  <div class="title">Public Endpoint Permissions</div>
                  <div class="grid options sm" style="margin-top: 20px;">
                    <el-checkbox-button class="btn" v-model="bucket.settings.publicpermissions.canget">GET</el-checkbox-button>
                    <el-checkbox-button class="btn" v-model="bucket.settings.publicpermissions.canpost">POST</el-checkbox-button>
                    <el-checkbox-button class="btn" v-model="bucket.settings.publicpermissions.candelete" disabled>DELETE</el-checkbox-button>
                    <el-checkbox-button class="btn" v-model="bucket.settings.publicpermissions.canput" disabled>PUT</el-checkbox-button>
                    <el-checkbox-button class="btn" v-model="bucket.settings.publicpermissions.canpatch" disabled>PATCH</el-checkbox-button>
                  </div>
                </div>
              </Transition>

            </div>

            <div class="grid options_block">
              <div class="title">Usefull stuff</div>
              <div class="grid options">
                <el-checkbox-button class="btn" v-model="bucket.settings.redirectaftersubmit.active">Redirect on submit</el-checkbox-button>
                <el-checkbox-button class="btn" v-model="bucket.settings.setcreatedAt">Created at</el-checkbox-button>
                <el-checkbox-button class="btn" v-model="bucket.settings.noempty">No empty data</el-checkbox-button>
                <el-checkbox-button class="btn" v-model="bucket.settings.noduplicate" disabled>No duplicates</el-checkbox-button>
              </div>

              <div class="grid" v-if="bucket.settings.redirectaftersubmit.active" style="margin-top: 20px;">
                <el-form-item label="">
                  <el-input type="text" v-model="bucket.settings.redirectaftersubmit.destination" placeholder="enter redirect destination" ></el-input>
                </el-form-item>
              </div>
            </div>

            <div class="grid options_block">
              <div class="title">Nice to have</div>
              <div class="grid options">
                <el-checkbox-button class="btn" v-model="bucket.settings.uniqueid" disabled>Unique ID</el-checkbox-button>
                <el-checkbox-button class="btn" v-model="bucket.settings.webhook.active" disabled>Webhooks</el-checkbox-button>
                <!-- <el-checkbox-button class="btn" v-model="bucket.settings.definedatamodel.active" disabled>Define Data Model</el-checkbox-button> -->
                <!-- <el-checkbox-button class="btn" v-model="bucket.settings.reduceoutput.active" disabled>Reduce output</el-checkbox-button> -->
              </div>
            </div>

            <div class="grid" style="grid-column: auto; grid-auto-flow: column; justify-content: space-between; margin-top: 40px;">

              <div class="grid" style="justify-content: start; grid-auto-flow: column; grid-column-gap: 12px;">

                <el-button class="btn simple" @click="askforbucketdelete.active = false" v-if="askforbucketdelete.active">cancel deletion</el-button>

                <el-button class="btn red" @click="$store.dispatch('buckets/deleteBucket', bucket), editroute = false, askforbucketdelete.active = false" v-if="editroute && askforbucketdelete.active">
                  Yes, delete this Endpoint
                </el-button>

                <el-button class="btn red icon" @click="askforbucketdelete.active = true" v-if="editroute && !askforreset.active && !askforbucketdelete.active">
                  <unicon name="trash-alt" />
                </el-button>

                <el-button class="btn simple" @click="askforreset.active = false" v-if="askforreset.active">cancel reset</el-button>

                <el-button class="btn red border" @click="askforreset.active = true" v-if="editroute && !askforreset.active && !askforbucketdelete.active">
                  reset data
                </el-button>

                <el-button class="btn red" @click="$store.dispatch('buckets/resetBucket', bucket), askforreset.active = false" v-if="editroute && askforreset.active">
                  yes, reset data
                </el-button>

              </div>

              <div class="grid" style="justify-content: end; grid-auto-flow: column; grid-column-gap: 12px;">

                <el-button class="btn simple" @click="addroute = false, settingsopen = false, changeroute = false, askforreset.active = false, askforbucketdelete.active = false">close</el-button>

                <el-button class="btn green" :disabled="doubleroute" @click="$store.dispatch('buckets/createBucket', bucket), addroute = false, settingsopen = false, changeroute = false" v-if="addroute && !askforreset.active">save endpoint</el-button>

                <el-button class="btn green" @click="$store.dispatch('buckets/updateBucket', bucket).then(() => {reloadBucket(bucket)}), settingsopen = false, changeroute = false, help.open = false" v-if="editroute && !askforreset.active && !askforbucketdelete.active">update endpoint</el-button>

              </div>

            </div>
            
          </div> <!-- settingsopen -->
          </Transition>

            <el-dialog
              v-model="migrationDialog"
              width="fit-content"
              custom-class="maxw50 migrationDialog"
              @closed="$refs.migrationUploadform.clearFiles()"
            >

            <div class="grid fromroute">

              <div class="title">
                Migrate from another dataset
              </div>

              <el-form-item label="">
                <el-input type="text" v-model="migration.route" placeholder="enter source or From-endpoint"></el-input>
              </el-form-item>

              <el-form-item label="">
                <el-input type="text" v-model="migration.jwt" placeholder="enter key"></el-input>
              </el-form-item>

            </div>

            <div class="grid upload">

              <div class="title">
                or upload an array as .json
              </div>

              <el-upload
                style="margin: 0px;"
                class="upload-json"
                drag
                action="/"
                :auto-upload="false"
                :on-change="handleImport"
                :show-file-list="true"
                ref="migrationUploadform"
              >
                <!-- <unicon name="file-upload-alt" fill="#fff" height="40px" width="40px" /> -->
                <div class="el-upload__text">
                  Drop file here or <em>click to upload</em>
                </div>
              </el-upload>

              <!-- <vue-json-pretty :data="migration.fileContent" v-if="migration.jsoneditorvisible" /> -->

            </div>

            <div class="grid options" v-if="bucketdata.length > 0">
              <el-checkbox-button class="btn" v-model="migration.mergedata">merge with existing data</el-checkbox-button>
            </div>

            <div class="grid actions">
              <el-button class="btn simple" @click="migrationDialog = false, migration.uploadFile = null, migration.fileContent = []">close window</el-button>
              <el-button class="btn green" @click="$store.dispatch('buckets/migrateBucket', {migration: migration, bucket: bucket}), migrationDialog = false">migrate<span v-if="bucketdata.length > 0 && migration.mergedata" style="margin-left: 4px;">and merge</span></el-button>
            </div>

              <!-- {{$store.state.buckets.merged}} -->

            </el-dialog>


            <el-drawer
              v-model="rapidocsdrawer"
              size="80%"
              custom-class="rapidocsdrawer"
              @opened="loadRapidocs(bucket)"
            >
              
<!--               <rapi-doc
                spec-url = "/static/default.json"
                ref="rapidoc"
                render-style = "read"
                theme = 'dark'
                show-header = 'false'
                show-info = 'false'
                primary-color="#6236FF"
                allow-authentication ='false'
                allow-server-selection = 'false'
                allow-try="false"
                update-route="false"
                bg-color="#19224A"
                style = "height:100%; width:80%"
              > </rapi-doc> -->

            </el-drawer>

            <el-drawer
              v-model="backupsdrawer"
              size="fit-content"
              custom-class="backupsdrawer blue"
            >
            <div style="height: 100vh;" v-loading="$store.state.buckets.loading">

            <!-- <span style="color: #fff;">{{$store.state.buckets.loading}}</span> -->
            <el-scrollbar height="100%">
              <!-- <div>Backup List</div> -->
              <div class="grid" style="padding: 64px 64px 64px 48px;" >
                <!-- <span style="color: #fff;">{{$store.state.buckets.backups}}</span> -->
                <div class="grid" style="margin-bottom: 50px;">
                  <el-button class="btn border block" :class="{red: backupslist.length > 25, green: backupslist.length <= 25}" @click="$store.dispatch('buckets/createBackup', {bucket: bucket})" :disabled="backupslist.length > 25">
                    <span v-if="backupslist.length <= 25">create manual backup</span>
                      <span v-if="backupslist.length > 25">exeeded 25 Backups</span>
                  </el-button>
                </div>
                <div class="grid backups">
                  <div class="grid backup" :class="{green: askforrestore.active && askforrestore.object.file === backup.file, disabled: askforrestore.active && askforrestore.object.file && askforrestore.object.file !== backup.file || askfordelete.active && askfordelete.object.file && askfordelete.object.file !== backup.file, red: askfordelete.active && askfordelete.object.file === backup.file}" v-for="(backup, index) in backupslist" :key="index">
                    <div>
                      <span v-if="askforrestore.active && askforrestore.object.file === backup.file">Restore Backup from </span>
                      <span v-if="askfordelete.active && askfordelete.object.file === backup.file">Delete Backup made </span>
                    {{backup.timestamp}}</div>
                    <!-- <div v-if="askforrestore.active && askforrestore.object.file !== backup.file">{{backup.timestamp}}</div> -->
                    <div v-if="askforrestore.object?.file !== backup.file && askfordelete.object?.file !== backup.file" style="opacity: .7; font-size: 10px;">{{backup.size}} MB</div>

                    <div class="grid actions">
                      <el-button class="btn xs simple"
                        v-if="askforrestore.active && askforrestore.object.file === backup.file || askfordelete.active && askfordelete.object.file === backup.file"
                        @click="askforrestore = {active: false, object: {}}, askfordelete = {active: false, object: {}}"
                      >
                      cancel</el-button>

                      <el-button class="btn xs dark" v-if="askforrestore.active && askforrestore.object.file === backup.file"
                        @click="$store.dispatch('buckets/restoreBackup', {bucket: bucket, backup: backup})
                          .then(() => {
                            askforrestore = {
                              active: false,
                              object: {}
                            },
                            backupsdrawer = false
                          })"
                      >Yes, do it</el-button>
                      <el-button class="btn xs dark" v-if="askfordelete.active && askfordelete.object.file === backup.file"
                        @click="$store.dispatch('buckets/deleteBackup', {bucket: bucket, backup: backup})
                          .then(() => {
                            askfordelete = {
                              active: false,
                              object: {}
                            },
                            $store.dispatch('buckets/getBackups', bucket)
                          })"
                      >Yes, do it</el-button>

                      <el-button class="btn xs border violet" v-if="!askforrestore.active && !askfordelete.active" @click="askforrestore = {active: true, object: backup}">restore</el-button>
                      <span class="grid delete" v-if="!askforrestore.active && !askfordelete.active"><unicon class="grid" name="trash-alt" fill="#878787" @click="askfordelete.active = true, askfordelete.object = backup" /></span>
                    </div>

                  </div>
                </div>
              </div>
            </el-scrollbar>

          </div>

            </el-drawer>

          <div class="grid help_section" v-if="!settingsopen && help.open">

            <!-- {{swagger}} -->

<!--             <el-drawer
              v-model="rapidocsdrawer"
              size="80%"
              @opened="loadRapidocs(bucket)"
            >
              
              <rapi-doc
                spec-url = "/static/default.json"
                ref="rapidoc"
                render-style = "read"
                theme = 'dark'
                show-header = 'false'
                show-info = 'false'
                primary-color="#00AE48"
                allow-authentication ='false'
                allow-server-selection = 'false'
                allow-try="false"
                style = "height:100%; width:80%"
              > </rapi-doc>

            </el-drawer> -->

            

            <div class="grid title">Usefull Informations</div>

            <div class="grid" style="justify-content: start; grid-row-gap: 12px;">
<!--               <el-tag size="large" type="success">GET {{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-tag>
              <el-tag size="large">POST {{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-tag>
              <el-tag size="large" type="warning">PUT {{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-tag>
              <el-tag size="large" type="info">PATCH {{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-tag>
              <el-tag size="large" type="danger">DELETE {{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-tag> -->

              <el-button class="btn split border twice green block" @click="rapidocsdrawer = true"><strong>GET</strong>{{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-button>
              <el-button class="btn split border twice blue block"><strong>POST</strong>{{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-button>
              <el-button class="btn split border twice yellow block"><strong>PUT</strong>{{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-button>
              <el-button class="btn split border twice red block"><strong>DELETE</strong>{{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-button>
              <el-button class="btn split border twice violet block"><strong>PATCH</strong>{{bucket.server}}{{bucket.zone}}.nitros.cloud/{{bucket.route}}</el-button>

<!--               <rapi-doc
                ref="rapidoc2"
                render-style = "read"
                theme = 'dark'
                show-header = 'false'
                show-info = 'false'
                primary-color="#00AE48"
                allow-authentication ='false'
                allow-server-selection = 'false'
                allow-try="false"
                style = "height:100%; width:80%"
              > </rapi-doc> -->
<!--               <el-collapse accordion>
                <el-collapse-item name="1">
                  <template #title>
                    Consistency<el-icon class="header-icon">
                      <info-filled />
                    </el-icon>
                  </template>
                  <div>
                    Consistent with real life: in line with the process and logic of real
                    life, and comply with languages and habits that the users are used to;
                  </div>
                  <div>
                    Consistent within interface: all elements should be consistent, such
                    as: design style, icons and texts, position of elements, etc.
                  </div>
                </el-collapse-item>
                <el-collapse-item title="Feedback" name="2">
                  <div>
                    Operation feedback: enable the users to clearly perceive their
                    operations by style updates and interactive effects;
                  </div>
                  <div>
                    Visual feedback: reflect current state by updating or rearranging
                    elements of the page.
                  </div>
                </el-collapse-item>
              </el-collapse> -->

            </div>

<!--             <div>
              <p>API keys</p>
              <div class="grid apikeys">
                <div class="grid apikey" v-for="(apikey, index) in $store.state.user.user.apikeys" :key="index">
                  <span @click="getapikey(apikey)">{{apikey.uid}}</span>
                  <span><el-switch
                    v-model="apikey.active"
                    class="ml-2"
                    size="small"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                  /></span>
                </div>
              </div>
            </div>

            <el-dialog
              v-model="apikeyVisible"
              width="fit-content"
              @close="token = ''"
              custom-class="green maxw50"
              center
            >
              <span>{{apitoken}}</span>
              <template #footer>
                <span class="dialog-footer">
                  <el-button class="btn dark" @click="apikeyVisible = false"
                    >Confirm</el-button
                  >
                </span>
              </template>
            </el-dialog> -->

          </div>


          <!-- <p>{{bucket}}</p> -->
          <!-- <p>{{newbucket}}</p> -->

        </el-form>

      </div>

      <Transition name="fade">
      <el-scrollbar height="100%">

        <!-- <LineChart :bucketchart="bucketchart" v-if="bucketdata.length > 0" /> -->

          <div class="grid bucketdata" v-if="editroute && !settingsopen && !help.open">
            <!-- <el-button @click="$store.dispatch('buckets/getBucketdata', bucket)">get data</el-button> -->
            <div class="grid" v-if="bucketdata.length === 0" style="color: #fff; height: 10vh; justify-items: center; align-content: center; grid-row-gap: 16px;">
              no data yet
              <el-button class="btn xs border green" @click="migrationDialog = true">migrate or upload data</el-button>
            </div>

            <div v-else class="grid recordset" :class="{opacity30: settingsopen}" v-for="(recordset, index) in bucketdata" :key="index">
              <!-- <span>{{recordset}}</span> -->
              <div class="grid" @click="recordsetinview = {...recordset}, recordsetVisible = true" style="cursor: pointer;">
                {{recordset.uniqueID || 'View details' || recordset._id}}
              </div>
              <div class="grid">
                {{recordset.createdat || recordset.createdAt}}
              </div>
            </div>

            <!-- {{bucketdata.length}} -->

            <el-pagination
              :page-sizes="[25, 100, 200, 300, 400]"
              :small="true"
              :hide-on-single-page="true"
              :currentPage="page"
              :page-size="pageSize"
              layout="sizes, prev, pager, next"
              :total="this.$store.state.buckets.bucketdata.length"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />

                <el-dialog
                  v-model="recordsetVisible"
                  width="fit-content"
                  custom-class="maxw50"
                  @close="consol(recordsetinview)"
                >
                  <!-- <span>{{recordsetinview}}</span> -->
                  <vue-json-pretty :data="recordsetinview" />
    <!--               <json-vuer :value="recordsetinview" 
                       :indentWidth="4" 
                       :showObjectSize="true" 
                       :showDataTypes="true" 
                       :copyable="true"/> -->

                  <template #footer>
                    <!-- <span class="dialog-footer">
                      <el-button class="btn dark" @click="recordsetVisible = false"
                        >Close</el-button
                      >
                    </span> -->
                  </template>
                </el-dialog>
              
          </div>
        </el-scrollbar>
        </Transition>

    </div> <!-- bucketdetails -->

  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
// import 'rapidoc'
import swagger from '/public/static/swagger2.json'
import { ElMessage } from 'element-plus'
import checkauth from '@/Mixins/checkauth'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
// import LineChart from "./micro/LineChart.vue"
export default {
  name: 'Buckets',
  mixins: [checkauth],
  props: {

  },
  components: {
    VueJsonPretty,
    // LineChart
  },
  data () {
    return {
      // chartData: {
      //   labels: [ 'January', 'February', 'March' ],
      //   datasets: [ { data: [40, 20, 12] } ]
      // },
      // chartOptions: {
      //   responsive: true
      // },
      askforbucketdelete: {
        active: false
      },
      askforreset: {
        active: false
      },
      askforrestore: {
        active: false,
        object: {}
      },
      askfordelete: {
        active: false,
        object: {}
      },
      search: '',
      addroute: false,
      editroute: false,
      settingsopen: false,
      changeroute: false,
      showpublicroute: true,
      rapidocsdrawer: false,
      backupsdrawer: false,
      swagger: swagger,
      migrationDialog: false,
      migration: {
        route: '',
        jwt: '',
        uploadFile: null,
        fileContent: [],
        jsoneditorvisible: false,
        mergedata: true
      },
      // apikeyVisible: false,
      // token: '',
      recordsetVisible: false,
      recordsetinview: {},
      help: {
        open: false
      },
      bucket: {
        _id: ''
      },
      newbucket: {
        serverstring: this.computedserverstring,
        connprotocol: this.$store.state.buckets.defaults.connprotocol,
        server: this.$store.state.buckets.defaults.server,
        zone: this.$store.state.buckets.defaults.zone,
        domain: this.$store.state.buckets.defaults.domain,
        route: '',
        settings: {
          backup: true,
          public: false,
          publicpermissions: {
            canget: false,
            canpost: false,
            candelete: false,
            canput: false,
            canpatch: false,
          },
          history: false,
          redirectaftersubmit : {
            active : false,
            destination : ''
          },
          setcreatedAt : true,
          noduplicate : false,
          noempty : false,
          uniqueid : true,
          webhook : {
            active : false,
            hook : ''
          },
          definedatamodel : {
            active : false,
            datamodel : {}
          },
          reduceoutput : {
            active : false,
            output : {}
          }
        }
      },
      page: 1,
      pageSize: 25,
      rules: {
          email: [
              { required: true, type: 'email', message: 'please input a valid email address', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(val)
      this.pageSize = val
    },
    handleCurrentChange (val) {
      console.log(val)
      this.page = val
    },
    openDocs () {
      window.open(process.env.VUE_APP_DOCS_URL + '/?protocol=' + this.bucket.connprotocol + '&url=' + this.bucket.server + this.bucket.zone + this.bucket.domain + '&path=' + this.bucket.route, '_new')
    },
    reloadBucket (bucket) {
      console.log('reloadBucket: ', bucket)
      console.log(_.find(this.$store.state.buckets.buckets, {'_id': bucket._id}))
      this.bucket = _.find(this.$store.state.buckets.buckets, {'_id': bucket._id})
    },
    loadRapidocs (bucket) {
      console.log(bucket)
      // this.rapidocsdrawer = true
      // console.log(this.$refs.ruleFormRef)
      console.log(this.swagger.paths)
      this.swagger.paths['/pet'] = '/test1'
      console.log(this.swagger.paths)
      // this.$refs.rapidoc.loadSpec(this.swagger)
      // var that = this
      // setTimeout(function() {
      //   console.log(that.$refs.rapidoc2)
      //   that.$refs.rapidoc2.loadSpec(this.swagger)
      // }, 2000)
      // if (this.$refs.rapidoc2 !== undefined) {
      //   this.$refs.rapidoc2.loadSpec(this.swagger)
      // } else {
      //   // console.log(this.$refs)
      //   // this.loadRapidocs(bucket)
      // }
    },
    saveFile () {
      // console.log(_.forEach(this.bucketdata, function(o) {
      //   console.log('o ', o)
      //   return _.omit(o, ['_id'])
      // }))
      // var downloaddata = _.forEach(this.bucketdata, function(o) {
      //   console.log('o ', o)
      //   return _.omit(o, ['_id'])
      // })
      // console.log(downloaddata)
      // const data = JSON.stringify(this.bucketdata)
      const data = JSON.stringify(_.reverse(this.$store.state.buckets.bucketdata))
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
      a = document.createElement('a');
      a.download = this.bucket.server + '_' + this.bucket.route + "_" + moment().format('DD.MM.YYYY') + '_' + moment().format('HH:mm:ss') + ".json";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    handleImport(file) {
      this.migration.uploadFile = file
      let reader = new FileReader()
      reader.readAsText(this.migration.uploadFile.raw)
      reader.onload = async (e) => {
        try {
          this.migration.fileContent = JSON.parse(e.target.result)
          console.log(JSON.parse(e.target.result))
          this.migration.jsoneditorvisible = true
          // this.$store.commit('LOAD_COINLIST', JSON.parse(e.target.result))
          // this.uploadopen = false
        } catch (err) {
          console.log(`Load JSON file error: ${err.message}`)
        }
      }
    },
    valchange (newVal, oldVal) {
      console.log(newVal, oldVal)
    },
    consol (recordsetinview) {
      console.log(recordsetinview)
    },
    getapikey (apikey) {
      this.$store.dispatch('user/getApikey', apikey).then((token) => {
        console.log(token)
        this.apikeyVisible = true
        this.apitoken = token
      })
    },
    changeServer (connprotocol, server, zone, domain) {
      // console.log(connprotocol, server, zone, domain)
      this.bucket.connprotocol = connprotocol
      this.bucket.server = server
      this.bucket.zone = zone
      this.bucket.domain = domain
    },
    copySuccess (e) {
      console.log(e)
      ElMessage({
        message: e.text + ' copied to clipboard',
        type: 'success',
      })
    }
  },
  watch: {
    // buckets: function(newval, oldval) {
    //   console.log('changes: ', oldval)
    //   // console.log(_.find(oldval, {'_id': this.bucket._id}))
    //   // this.bucket = _.find(newval, {'_id': this.bucket._id})
    //   // this.bucket?.settings.public ? this.showpublicroute = true : this.showpublicroute = false
    // }
  },
  computed: {
    doubleroute () {
      var that = this
      return _.chain(this.$store.state.buckets.buckets).filter({'server': this.bucket.server}).some(function (o) {
        return o.route.toString().toLowerCase() === that.bucket.route.toLowerCase()
      }).value()
    },
    backupslist () {
      return _.reverse(this.$store.state.buckets.backups)
    },
    computedserverstring () {
      // console.log('computedserverstring: ', this.$store.state.buckets.defaults)
      return this.$store.state.buckets.defaults.connprotocol + this.$store.state.buckets.defaults.server + this.$store.state.buckets.defaults.zone + this.$store.state.buckets.defaults.domain
    },
    bucketchart () {
      var labels = _.groupBy(_.reverse(this.bucketdata), function(o) {
        // console.log(moment(o.createdAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY'))
        return moment(o.createdAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY')
      })
      // console.log(labels)
      // console.log(_.map(labels, function(o) { return o.length }))
      return {
        labels: _.keys(labels),
        datasets: [ { label: 'New records', data: _.map(labels, function(o) { return o.length }) } ]
      }
    },
    buckets () {
      var that = this
      return _.chain(this.$store.state.buckets.buckets).filter(function (o) {
        return o.route.toString().toLowerCase().includes(that.search.toLowerCase())
      }).value()
    },
    bucketdata () {
      return _.reverse(this.$store.state.buckets.bucketdata).slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    }
  },
  beforeMount () {
    this.$store.dispatch('buckets/getBuckets').then(() => this.$store.dispatch('buckets/getBucketsdata'))
  },
  mounted () {
    // console.log('mounted: ', this.$refs.rapidoc)
    // console.log('mounted2: ', this.$refs.rapidoc2)
    // this.$store.dispatch('buckets/getBucketsdata')
  },
  created () {
    this.$store.dispatch('buckets/getBucketsdata')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
