import { createApp } from 'vue'
import { store } from './store/store'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import VueClipboard from 'vue3-clipboard'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import JsonVuer from '../node_modules/json-vuer'
import JsonEditor from 'vue-json-edit'
import Unicon from 'vue-unicons'
import { uniSlidersVAlt, uniFileLockAlt, uniHistory, uniArchive, uniTrashAlt, uniTrash, uniTachometerFastAlt, uniUser, uniCopy, uniNinja, uniShieldPlus, uniInfoCircle, uniFileShareAlt, uniEditAlt, uniFileDownloadAlt, uniFileUploadAlt, uniQuestionCircle, uniCheckCircle, uniRotate360, uniEllipsisH } from 'vue-unicons/dist/icons'

Unicon.add([uniSlidersVAlt, uniFileLockAlt, uniHistory, uniArchive, uniTrashAlt, uniTrash, uniTachometerFastAlt, uniUser, uniCopy, uniNinja, uniShieldPlus, uniInfoCircle, uniFileShareAlt, uniEditAlt, uniFileDownloadAlt, uniFileUploadAlt, uniQuestionCircle, uniCheckCircle, uniRotate360, uniEllipsisH])

const app = createApp(App)

app.config.productionTip = false
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(ElementPlus)
app.use(Unicon, {
	fill: '#fff',
	height: 16,
	width: 16
})
// app.use(JsonVuer)
app.use(JsonEditor)
app.mount('#app')
