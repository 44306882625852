<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
@import 'assets/css/app.css';
@import 'assets/css/grid.css';
</style>
