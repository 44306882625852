// import { getUA } from 'mobile-device-detect'
// import _ from 'lodash'
// import moment from 'moment'
export default {
    data () {
        return {

        }
     },
    sockets: {

    },
     methods: {

     },
     computed: {

     },
     beforeMount () {
        // this.$store.commit('appversion/SET_ISSHOWINGNOTIFICATION', false)
     },
     created () {
        var that = this
        this.$store.dispatch('appversion/getAppversion')
        setInterval(function(){
            that.$store.dispatch('appversion/getAppversion')
        }, 300000);
     }
};