<template>
  <div class="grid loginpage" style="height: 100vh; justify-content: center; align-content: center;" v-loading="loading">
    <div class="grid login hidden-xs">
      <div class="grid logo">
        <!-- <img src="/static/img/logo_green.png"> -->
        <img src="/static/img/logo_light_n@2x.png" height="80" width="80">
      </div>

      <Transition name="fade">
        <div class="grid" v-if="magiclinksent">
          <span style="font-style: italic; color: #fff; text-align: center; font-size: 14px; font-weight: 300;">We sent an email with a magic link to {{login.email}}, click on it to log in.</span>
        </div>
      </Transition>

      <div class="grid" style="margin-top: 40px;">
        <el-form
          ref="ruleFormRef"
          :model="login"
          :rules="rules"
          label-width="120px"
          class="grid loginform"
          status-icon
          label-position="top"
        >
          <el-form-item label="" prop="email">
            <el-input type="email" v-model="login.email" placeholder="name@example.com" ref="emailInput" />
          </el-form-item>

          <el-button class="btn green block" @click="loginuser('ruleFormRef')">Login or sign up</el-button>

        </el-form>
      </div>
    </div>
    <div class="visible-xs" style="color: #fff;">
      Please use a Desktop device
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  props: {
    
  },
  data () {
    return {
      login: {
        email: ''
      },
      loading: false,
      magiclinksent: false,
      rules: {
          email: [
              { required: true, type: 'email', message: 'please input a valid email address', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    loginuser (formName) {
    console.log(this.login)
      this.$refs[formName].validate((valid) => {
        this.loading = true
        if (valid) {
          console.log('valid')
          this.$store.dispatch('magiclink/sendLink', {email: this.login.email}).then(() => {
            this.magiclinksent = true
            this.loading = false
          })
        } else {
          console.log('error login')
        }
      })
    }
  },
  mounted () {
    this.$refs.emailInput.focus()
    // console.log(this.$refs.emailInput)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
