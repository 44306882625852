import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
// import VuexPersistence from 'vuex-persist'
// import _ from 'lodash'
import axios from "axios"
import user from '@/store/user.js'
import buckets from '@/store/buckets.js'
import magiclink from '@/store/magiclink.js'
import appversion from '@/store/appversion.js'
/* eslint-disable */

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage
// })

export const store = createStore({
  plugins: [createPersistedState({
    paths: ['authenticated', 'user.jwt', 'appversion'],
  })],
  modules: {
    user: user,
    buckets: buckets,
    magiclink: magiclink,
    appversion: appversion
  },
  state () {
  	return {
  		loading: false,
      loadingtext: '',
      authenticated: false,
  		api: {}
  	}
  },
  actions: {
		setApistatus ({commit}, data) {
      axios
        .get(process.env.VUE_APP_HANDLER_URL
        ).then((data) => {
          console.log(data)
        }, (error) => {
          console.log('error: ', error)
        })
      return new Promise((resolve) => {
        resolve('ok')
        commit('SET_APISTATUS', data)
      })
    },
    setLoading ({commit}, loading) {
      commit('SET_LOADING', loading)
    },
    setAuthenticated ({commit}, data) {
      console.log(data)
      commit('SET_AUTHENTICATED', data)
    }
  },
  mutations: {
    SET_LOADING (state, loading) {
      state.loading = loading.active
      state.loadingtext = loading.text
    },
    SET_APISTATUS (state, data) {
      state.api = data
    },
    SET_AUTHENTICATED (state, data) {
      state.authenticated = data
    }
  },
})