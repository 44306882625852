// import Vue from 'vue'
// import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/Login.vue'
import Dashboard from '@/components/Dashboard.vue'
import Site404 from '@/components/Site404.vue'
import {store} from '../store/store'

// Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      next()
      store.dispatch('buckets/getDefaults').then(() => {
        console.log('getting defaults on route')
      })
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/billing',
    component: Dashboard,
    meta: {
      account: true,
      billing: true
    },
    beforeEnter: (to, from, next) => {
      // next()
      store.dispatch('user/getUser').then(() => {
        console.log('getting user')
        next()
      })
    }
  },
  {
    path: '/magiclink/:linktoken',
    name: 'Magiclink',
    beforeEnter: (to, from, next) => {
      // console.log(to.params)
      // console.log(from)
      // next('/')
      store.dispatch('magiclink/checkLink', {linktoken: to.params.linktoken}).then(() => {
        console.log('Magiclink found')
        next('/')
      }, () => {
        console.log('Magiclink not found')
        localStorage.clear()
        // location.reload()
        next('/login')
      })
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: Site404
  }
]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

export default router
