// import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate"
// import _ from 'lodash'
import axios from "axios"
import { ElNotification } from 'element-plus'
/* eslint-disable */

// initial state
const state = () => ({
  loading: false,
  buckets: [],
  bucketdata: [],
  defaults: {
    connprotocol: "http://",
    server: "localhost:5000",
    zone: "",
    domain: "",
    disk: "../buckets/"
  },
  merged: [],
  backups: []
})

// getters
const getters = {
  // maxsources: state => {
  //   return 20
  // }
}

// actions
const actions = {
  getDefaults ({commit, dispatch, rootState}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_HANDLER_URL + '/getdefaults',
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data) => {
          console.log('getDefaults response: ', data.data)
          commit('SET_DEFAULTS', data.data)
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  },
  getBuckets ({commit, dispatch, rootState}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_HANDLER_URL + '/getbuckets',
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data) => {
          console.log('getBuckets response: ', data.data)
          commit('SET_BUCKETS', data.data)
          // dispatch('checkDestinations')
          // commit('SET_MAXSOURCES')
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  },
  createBucket ({commit, rootState, dispatch}, data) {
    console.log('createBucket: ', data)
    if (data.server === 'localhost:5000') {
      var server = data.connprotocol + 'localhost'
    } else {
      var server = data.connprotocol + data.server + data.zone + data.domain;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(server + ':7000/createroute', data,
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data) => {
          console.log(data)
          dispatch('getBuckets')
          ElNotification({
            title: 'Success',
            message: 'Endpoint created',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          ElNotification({
            title: 'Error',
            message: error.response.data,
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  updateBucket ({commit, rootState, dispatch}, data) {
    console.log('updateBucket: ', data)
    if (data.server === 'localhost:5000') {
      var server = data.connprotocol + 'localhost'
    } else {
      var server = data.connprotocol + data.server + data.zone + data.domain;
    }
    return new Promise((resolve, reject) => {
      axios
        // .post(process.env.VUE_APP_HANDLER_URL + '/updateroute', {bucket: data},
        .post(server + ':7000/updateroute', {bucket: data},
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data) => {
          console.log(data)
          dispatch('getBuckets')
          ElNotification({
            title: 'Success',
            message: 'Endpoint updated',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          ElNotification({
            title: 'Error',
            message: 'Shit happens, please try again',
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  deleteBucket ({commit, rootState, dispatch}, data) {
    console.log(data)
    if (data.server === 'localhost:5000') {
      var server = data.connprotocol + 'localhost'
    } else {
      var server = data.connprotocol + data.server + data.zone + data.domain;
    }
    console.log(server)
    return new Promise((resolve, reject) => {
      axios
        .post(server + ':7000/deleteroute', {bucket: data},
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data) => {
          console.log(data)
          dispatch('getBuckets')
          ElNotification({
            title: 'Success',
            message: 'Endpoint deleted',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          ElNotification({
            title: 'Error',
            message: 'Shit happens, please try again',
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  resetBucket ({commit, rootState, dispatch}, data) {
    console.log(data)
    var server = data.connprotocol + data.server + data.zone + data.domain;
    return new Promise((resolve, reject) => {
      axios
        .put(server + '/' + data.route, [],
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data2) => {
          console.log('getBucketdata response: ', data2)
          ElNotification({
            title: 'Success',
            message: 'Endpoint resetted',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          // var size = new Blob([JSON.stringify(data2.data)]).size
          // commit('SET_BUCKETDATA', {data: data2.data, bucket: data, quota: {
          //   records: data2.data.length,
          //   size: (size / 1024 ** 2).toFixed(4)
          // }})
          // console.info((size / 1024 ** 2).toFixed(4));
          dispatch('getBucketdata', data)
          // commit('SET_MAXSOURCES')
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          ElNotification({
            title: 'Error',
            message: 'Shit happens, please try again',
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  getBucketdata ({commit, dispatch, rootState}, data) {
    console.log('getBucketdata ', data)
    commit('SET_LOADING', true)
    var server = data.connprotocol + data.server + data.zone + data.domain;
    console.log(server)
    return new Promise((resolve, reject) => {
      axios
        // .get(process.env.VUE_APP_API_URL + '/' + data.route,
        .get(server + '/' + data.route,
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data2) => {
          console.log('getBucketdata response: ', data2)
          var size = new Blob([JSON.stringify(data2.data)]).size
          commit('SET_BUCKETDATA', {data: data2.data, bucket: data, quota: {
            records: data2.data.length,
            size: (size / (1024 ** 2)).toFixed(4)
          }})
          console.info((size / (1024 ** 2)).toFixed(4));
          commit('SET_LOADING', false)
          // dispatch('checkDestinations')
          // commit('SET_MAXSOURCES')
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          commit('SET_LOADING', false)
          reject(error)
        })
    })
  },
  getQuota ({commit, dispatch, rootState}, data) {
    // console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_HANDLER_URL + '/getquota',
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data2) => {
          console.log('getQuota response: ', data2)
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  },
  getBackups ({commit, rootState, dispatch}, data) {
    commit('SET_LOADING', true)
    console.log('getBackups: ', data)
    if (data.server === 'localhost:5000') {
      var server = data.connprotocol + 'localhost'
    } else {
      var server = data.connprotocol + data.server + data.zone + data.domain;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(server + ':7000/getbackups', {route: data._id},
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data) => {
          console.log(data)
          commit('SET_BACKUPS', data.data.backupfiles)
          commit('SET_LOADING', false)
          // dispatch('getBuckets')
          // ElNotification({
          //   title: 'Success',
          //   message: 'Endpoint created',
          //   type: 'success',
          //   duration: 2000,
          //   customClass: 'n_notification success'
          // })
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          commit('SET_LOADING', false)
          commit('SET_BACKUPS', [])
          // ElNotification({
          //   title: 'Error',
          //   message: error.response.data,
          //   type: 'error',
          //   duration: 2000,
          //   customClass: 'n_notification error'
          // })
          reject(error)
        })
    })
  },
  createBackup ({commit, rootState, dispatch}, data) {
    console.log('createBackup: ', data)
    commit('SET_LOADING', true)
    if (data.bucket.server === 'localhost:5000') {
      var server = data.bucket.connprotocol + 'localhost'
    } else {
      var server = data.bucket.connprotocol + data.bucket.server + data.bucket.zone + data.bucket.domain;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(server + ':7000/createbackup', {bucket: data.bucket},
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data2) => {
          console.log(data2)
          commit('SET_LOADING', false)
          ElNotification({
            title: 'Success',
            message: 'Backup created',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          dispatch('getBackups', data.bucket)
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          commit('SET_LOADING', false)
          // commit('SET_BACKUPS', [])
          ElNotification({
            title: 'Error',
            message: error.response.data,
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  restoreBackup ({commit, rootState, dispatch}, data) {
    console.log('restoreBackup: ', data)
    commit('SET_LOADING', true)
    if (data.bucket.server === 'localhost:5000') {
      var server = data.bucket.connprotocol + 'localhost'
    } else {
      var server = data.bucket.connprotocol + data.bucket.server + data.bucket.zone + data.bucket.domain;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(server + ':7000/restorebackup', {bucket: data.bucket, backup: data.backup},
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data2) => {
          console.log(data2)
          commit('SET_LOADING', false)
          dispatch('getBucketdata', data.bucket)
          ElNotification({
            title: 'Success',
            message: 'Backup restored',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          // commit('SET_BACKUPS', [])
          ElNotification({
            title: 'Error',
            message: error.response.data,
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  deleteBackup ({commit, rootState, dispatch}, data) {
    console.log('deleteBackup: ', data)
    commit('SET_LOADING', true)
    if (data.bucket.server === 'localhost:5000') {
      var server = data.bucket.connprotocol + 'localhost'
    } else {
      var server = data.bucket.connprotocol + data.bucket.server + data.bucket.zone + data.bucket.domain;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(server + ':7000/deletebackup', {bucket: data.bucket, backup: data.backup},
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data2) => {
          console.log(data2)
          commit('SET_LOADING', false)
          // dispatch('getBucketdata', data.bucket)
          ElNotification({
            title: 'Success',
            message: 'Backup restored',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          commit('SET_LOADING', false)
          // commit('SET_BACKUPS', [])
          ElNotification({
            title: 'Error',
            message: error.response.data,
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  maskBucket ({commit, rootState, dispatch}, data) {
    console.log('maskBucket: ', data)
    if (data.server === 'localhost:5000') {
      var server = data.connprotocol + 'localhost'
    } else {
      var server = data.connprotocol + data.server + data.zone + data.domain;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(server + ':7000/maskroute', {bucket: data},
          {
            headers: {
              'Authorization': 'Bearer ' + rootState.user.jwt
            }
          }
        ).then((data) => {
          console.log(data)
          dispatch('getBuckets')
          ElNotification({
            title: 'Success',
            message: 'Endpoint masked',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          ElNotification({
            title: 'Error',
            message: error.response.data,
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  migrateBucket ({commit, state, rootState, dispatch}, migration) {
    console.log('migrateBucket: ', migration)
    return new Promise((resolve, reject) => {
      axios
        .get(migration.migration.route.length > 0 ? migration.migration.route : '/',
          {
            headers: {
              'Authorization': 'Bearer ' + migration.migration.jwt
            }
          }
        ).then((data) => {
          console.log(data)
          var datafromsource = Array.isArray(data.data) ? data.data : []
          console.log(state.bucketdata)
          console.log('merged')
          console.log(migration.migration.fileContent)
          console.log(datafromsource)
          var datatoput;
          if (migration.migration.mergedata) {
            datatoput = _.concat([...state.bucketdata], datafromsource, migration.migration.fileContent)
          } else {
            datatoput = _.concat(datafromsource, migration.migration.fileContent)
          }
          console.log('datatoput: ', datatoput)
          commit('SET_MERGED', datatoput)
          // if (data.server === 'localhost:5000') {
          //   var server = data.connprotocol + 'localhost'
          // } else {
          //   var server = data.connprotocol + data.server + data.zone + data.domain;
          // }
          var server = migration.bucket.connprotocol + migration.bucket.server + migration.bucket.zone + migration.bucket.domain;
          console.log(server + '/' + migration.bucket.route)
          axios
          .put(server + '/' + migration.bucket.route, datatoput,
            {
              headers: {
                'Authorization': 'Bearer ' + rootState.user.jwt
              }
            }
          ).then((data2) => {
            console.log('migrateBucket .PUT response: ', data2)
            ElNotification({
              title: 'Success',
              message: 'Endpoint migrated',
              type: 'success',
              duration: 2000,
              customClass: 'n_notification success'
            })
            // var size = new Blob([JSON.stringify(data2.data)]).size
            // commit('SET_BUCKETDATA', {data: data2.data, bucket: data, quota: {
            //   records: data2.data.length,
            //   size: (size / 1024 ** 2).toFixed(4)
            // }})
            // console.info((size / 1024 ** 2).toFixed(4));
            dispatch('getBucketdata', migration.bucket)
            // commit('SET_MAXSOURCES')
            resolve('ok')
          }, (error) => {
            console.log('error: ', error)
            ElNotification({
              title: 'Error',
              message: 'Shit happens, please try again',
              type: 'error',
              duration: 2000,
              customClass: 'n_notification error'
            })
            reject(error)
          })
          ElNotification({
            title: 'Success',
            message: 'Endpoint fetched',
            type: 'success',
            duration: 2000,
            customClass: 'n_notification success'
          })
          // resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          ElNotification({
            title: 'Error',
            message: error.response.data,
            type: 'error',
            duration: 2000,
            customClass: 'n_notification error'
          })
          reject(error)
        })
    })
  },
  getBucketsdata ({commit, dispatch, state, rootState}) {
    console.log('getBucketsdata ')
    _.forEach(state.buckets, function(o) {
      // console.log(o)
      var server = o.connprotocol + o.server + o.zone + o.domain;
      // console.log(server)
      return new Promise((resolve, reject) => {
        axios
          // .get(process.env.VUE_APP_API_URL + '/' + data.route,
          .get(server + '/' + o.route,
            {
              headers: {
                'Authorization': 'Bearer ' + rootState.user.jwt
              }
            }
          ).then((data2) => {
            // console.log('getBucketdata response: ', data2)
            var size = new Blob([JSON.stringify(data2.data)]).size
            commit('SET_BUCKETDATA', {data: data2.data, bucket: o, quota: {
              records: data2.data.length,
              size: (size / (1024 ** 2)).toFixed(4)
            }})
            // console.info((size / 1024 ** 2).toFixed(4));
            // dispatch('checkDestinations')
            // commit('SET_MAXSOURCES')
            resolve('ok')
          }, (error) => {
            console.log('error: ', error)
            reject(error)
          })
      })
    })
  },
}

// mutations
const mutations = {
  SET_LOADING (state, data) {
    state.loading = data
  },
  SET_BUCKETS (state, data) {
    state.buckets = data
  },
  SET_BUCKETDATA (state, data) {
    // console.log('e: ', data.bucket)
    state.bucketdata = data.data
    state.buckets[_.findIndex(state.buckets, { _id: data.bucket._id })].records = data.quota.records
    state.buckets[_.findIndex(state.buckets, { _id: data.bucket._id })].size = data.quota.size
  },
  SET_DEFAULTS (state, data) {
    state.defaults = data.defaults
  },
  SET_MERGED (state, data) {
    state.merged = data
  },
  SET_BACKUPS (state, data) {
    state.backups = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
