// import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate"
// import _ from 'lodash'
import axios from "axios"
import { ElNotification } from 'element-plus'
/* eslint-disable */

// initial state
const state = () => ({
  appversion: {},
  // isShowingNotification: false
})

// getters
const getters = {}

// actions
const actions = {
  getAppversion ({commit, state, rootState}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_HANDLER_URL + '/api/appversion'
        ).then((data) => {
          // console.log(data.data.appversion)
          // console.log('appversion: ', state.appversion.build)
          if (state.appversion.build === undefined) {
            commit('SET_APPVERSION', data.data.appversion)
          }
          if (state.appversion?.build?.number !== data.data.appversion.build.number) {
            console.log('need an update')
            // commit('SET_APPVERSION', data.data.appversion)
            // window.location.reload()
            // if (state.isShowingNotification) {
            //   console.log('already showing notification for update')
            // } else {
              ElNotification({
                title: 'Here we go',
                message: 'There´s an update available',
                type: 'info',
                duration: 0,
                dangerouslyUseHTMLString: true,
                position: 'bottom-left',
                customClass: 'n_notification orange clickable',
                onClick: () => { commit('SET_APPVERSION', data.data.appversion), commit('SET_ISSHOWINGNOTIFICATION', false), window.location.reload() }
              })
              // commit('SET_ISSHOWINGNOTIFICATION', true)
            // }
          } else {
            console.log('app is up to date')
          }
          // commit('user/SET_USER', data, {root: true})
          // commit('SET_AUTHENTICATED', true, {root: true})
          // commit('SET_ACCOUNT', data.data.profile.email, {root: true})
          resolve('ok')
        }, (error) => {
          console.log('error: ', error)
          // commit('SET_AUTHENTICATED', false, {root: true})
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_APPVERSION (state, data) {
    state.appversion = data
  },
  // SET_ISSHOWINGNOTIFICATION (state, data) {
  //   state.isShowingNotification = data
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
