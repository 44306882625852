// import { getUA } from 'mobile-device-detect'
// import _ from 'lodash'
// import moment from 'moment'
export default {
	data () {
		return {
			test: 'test mixin',
		}
	},
	sockets: {

	},
	methods: {
		logout () {
			localStorage.clear()
			// location.reload()
			this.$router.replace('/login')
		}
	},
	computed: {

	},
	beforeMount () {
		// console.log(this.$store.state.authenticated, this.$store.state.user.jwt)
		if (this.$store.state.authenticated && this.$store.state.user.jwt.length > 0) {
			// console.log('auth ok')
		} else {
			this.logout()
		}
	},
	created () {

	}
};